.EmptyCart {
    margin-top: 80px;
}

.Message {
    margin-top: 80px;
}

.CartContainer {
    padding-top: 80px;
    width: 84%;
    margin: 0 auto;
}

.CartItemDetails p {
    font-size: 12px;
    margin: 4px;
}

.CartListItem {
    text-align: left;
    display: grid;
    grid-template-columns: 2fr 1fr;
    border-bottom: solid black 1px;
}

.CartPriceQty {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    justify-content: right;
}

.CartItemPrice{
    text-align: right;
    margin-top: 24px;
}

.QtyControls {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    font-weight: 600;
}

.QtyControls button {
    height: 20px;
    border: none;
}

.QtyMinus {
    border-radius: 10px 0 0 10px;
    background-color: rgb(239, 239, 239);
}

.QtyPlus {
    border-radius: 0 10px 10px 0;
    background-color: rgb(239, 239, 239);
}

.QtyControls p {
    height: 20px;
    text-align: center;
    background-color: rgb(239, 239, 239);
    margin: 0;
}

.RemoveButton {
    border: none;
    text-transform: uppercase;
    text-align: right;
    font-size: 12px;
    background-color: white;
    letter-spacing: 1px;
    padding: 0;
}

.result-message.hidden {
    display: none;
}

.Subtotal {
    width: 50%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: end;
    align-items: baseline;
    margin: 20px 0 0 auto;
}

.Subtotal h3 {
    margin: 0;
}

.Subtotal h2 {
    margin: 0 0 30px 0;
}

.Taxes {
    width: 50%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: end;
    align-items: baseline;
    margin: 0 0 0 auto;
}

.Taxes h3 {
    margin: 0;
}

.Taxes h2 {
    margin: 0;
}

.Total {
    width: 50%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: end;
    align-items: baseline;
    margin: 0 0 0 auto;
}

.Total h2 {
    margin: 0;
}

.Total h1 {
    margin: 20px 0;
}
.THREECanvas canvas {
    width: 400px !important;
    height: 300px !important;
    z-index: 50;
    border-bottom: solid 1px #aaaaaa;
}

.THREEWindow {
    width: 100%;
    margin: 0 auto;
    position: fixed;
    z-index: 50;
    background-color: #ffffff;
}

.THREEContainer {
    background-color: #ffffff;
}

.WWProductMain {
    width: 100%;
    background-color: #ffffff;
}

.TopControlsSpacer {
    width: 100%;
    height: 300px;
}

.ControlSection {
    margin-bottom: 50px !important;
}

.THREEControls {
    display: inline-block;
    width: 84%;
    z-index: 1;
    margin: 0 auto;
}

.DimensionSliders {
    width: 60%;
    margin: 0 auto;
}

.ShelfControls {
    width: 60%;
    margin: 0 auto;
}

.ColorPicker {
    position: relative;
    width: 84%;
    height: 144px;
    margin: 20px auto 0px auto;
}

.Swatch {
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
    cursor: pointer;
}

.SwatchColor {
    width: 36px;
    height: 14px;
    border-radius: 2px;
}

.Popover {
    position: absolute;
    width: 100%;
    z-index: 2;
}

.Cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
}

.circle-picker {
    width: 150px !important;
    margin: 0 auto !important;
}

.circle-picker span div {
    margin-right: 7px !important;
    margin-left: 7px !important;
}

.CustomColorField {
    transform: translate(18px, -42px);
    width: 84px !important;
}

.CustomColorPicker {
    transform: translate(20px, -64px);
}

.WoodThumbs {
    display: grid;
    width: 230px;
    margin: 0 auto;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
}

.WoodNote {
    font-size: 12px;
}

.CheckFinishes {
    width: 100%;
    height: 200px;
    margin: 20px auto;
}

.FinishMockupBase {
    background-size: 100%;
    width: 200px;
    height: 200px;
    margin: 0 auto;
}

.FinishMockupMask {
    width: 200px;
    height: 200px;
    margin: 0 auto;
    mask-image: url('../images/FinishBaseMask.png');
    mask-size: 100%;
    mix-blend-mode: hard-light;
    transform: translateY(-200px);
}

.FinishMockupMask2 {
    width: 200px;
    height: 200px;
    margin: 0 auto;
    mask-image: url('../images/FinishBaseMask.png');
    mix-blend-mode: color;
    transform: translateY(-400px);
    mask-size: 100%;
}

.FinishMockupWood {
    width: 200px;
    height: 200px;
    margin: 0 auto;
    transform: translateY(-600px);
    background-size: 100%;
}

.Spacer {
    width: 100%;
    height: 40px;
}

.FAQ {
    padding-top: 40px;
}

.BottomSpacer {
    width: 100%;
    height: 100px;
}


@media only screen and (max-width: 800px) {

    .THREECanvas canvas {
        width: 400px !important;
        height: 300px !important;
        z-index: 50;
        border-bottom: solid 1px #aaaaaa;
    }

    .THREEWindow {
        width: 100%;
        margin: 0 auto;
        position: fixed;
        z-index: 50;
        background-color: #ffffff;
    }

    .THREEContainer {
        background-color: #ffffff;
    }

    .WWProductMain {
        width: 100%;
        background-color: #ffffff;
    }

    .TopControlsSpacer {
        width: 100%;
        height: 300px;
    }

    .ControlSection {
        margin-bottom: 50px !important;
    }

    .THREEControls {
        display: inline-block;
        width: 84%;
        z-index: 1;
        margin: 0 auto;
    }

    .DimensionSliders {
        width: 60%;
        margin: 0 auto;
    }

    .ShelfControls {
        width: 60%;
        margin: 0 auto;
    }

    .ColorPicker {
        position: relative;
        width: 84%;
        height: 144px;
        margin: 20px auto 0px auto;
    }

    .Swatch {
        padding: 5px;
        background: #fff;
        border-radius: 1px;
        box-shadow: 0 0 0 1px rgba(0,0,0,.1);
        display: inline-block;
        cursor: pointer;
    }

    .SwatchColor {
        width: 36px;
        height: 14px;
        border-radius: 2px;
    }

    .Popover {
        position: absolute;
        width: 100%;
        z-index: 2;
    }

    .Cover {
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        width: 100%;
    }

    .circle-picker {
        width: 150px !important;
        margin: 0 auto !important;
    }

    .circle-picker span div {
        margin-right: 7px !important;
        margin-left: 7px !important;
    }

    .CustomColorField {
        transform: translate(18px, -42px);
        width: 84px !important;
    }

    .CustomColorPicker {
        transform: translate(20px, -64px);
    }

    .WoodThumbs {
        display: grid;
        width: 230px;
        margin: 0 auto;
        grid-template-columns: 1fr 1fr 1fr;
        justify-items: center;
    }

    .CheckFinishes {
        width: 100%;
        height: 200px;
        margin: 20px auto -40px auto;
    }

    .FinishMockupBase {
        background-size: 100%;
        width: 200px;
        height: 200px;
        margin: 0 auto;
    }

    .FinishMockupMask {
        width: 200px;
        height: 200px;
        margin: 0 auto;
        mask-image: url('../images/FinishBaseMask.png');
        mask-size: 100%;
        mix-blend-mode: hard-light;
        transform: translateY(-200px);
    }

    .FinishMockupMask2 {
        width: 200px;
        height: 200px;
        margin: 0 auto;
        mask-image: url('../images/FinishBaseMask.png');
        mix-blend-mode: color;
        transform: translateY(-400px);
        mask-size: 100%;
    }

    .FAQ {
        padding-top: 40px;
    }

    .BottomSpacer {
        width: 100%;
        height: 100px;
    }

}
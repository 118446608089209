.CartIcon {
    right: 20px;
    top: 18px;
    position: fixed;
}

.App-header {
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100vw;
    background-color: #fff;
    height: 60px;
    border-bottom: solid #212121 10px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.20);
}

.MenuButton {
    position: fixed;
    left: 20px;
    top: 14px;
}

.HeaderTitle {
    letter-spacing: 2px;
    position: relative;
    font-weight: 600;
    font-size: calc(8px + 2vmin);
    text-transform: uppercase;
    color: black;
    padding-top: 12px;
    text-align: center;
    margin: 0 auto;
}  


@media only screen and (max-width: 800px) {
      
 
}

@media only screen and (max-width:500px) {
  

}


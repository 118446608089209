.AboutContainer {
    width: 84%;
    margin: 100px auto 0 auto;
}

.AboutContainer p {
    line-height: 22px;
    text-align: left;
}

.SubscribeForm {
    height: 300px;
    width: 84%;
    margin: 0 auto;
}

.VerticalLogo {
    margin-top: 60px;
}

.AboutBlurb {
    margin: 80px auto;
}
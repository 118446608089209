.ContactContainer {
    width: 84%;
    margin: 100px auto 0 auto;
}

.ContactContainer p {
    line-height: 22px;
}

.SubscribeForm {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    height: 200px;
}


.SupportEmail {
    margin-bottom: 60px;
}

.VerticalLogo {
    margin-top: 60px;
}
.CartBackdrop {
    color: #000;
    z-index: 100;
}

#AddToCartPopover div.MuiPopover-paper {
    border-radius: 20px !important;
}

.AddToCartPopoverContent {
    width: 300px;
    height: 200px;
    background-color: white;
    text-align: center;
}

.ProductLinkContainer a {
    text-decoration: none;
    color: white;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 32px;
}

.ProductLinkContainer {
    padding-top: 10px;
    width: 240px;
    height: 42px;
    text-transform: uppercase;
    margin: 20px auto;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    background-color: #212121;
}
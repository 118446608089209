

.LinkTest {
    margin-top: 200px;
}

.carousel-root {
    margin-top: 60px; 
    
}

.carousel .slide {
    max-height: 500px !important;
    
}

.legend {
    width: 100% !important;
    border-radius: 0 !important;
    height: 80px !important;
    transform: translate(-5vw, 40px);
    background: rgba(0, 0, 0, .25) !important;
    opacity: 1 !important;
    transition: none !important;
}

.CarouselTitle {
    text-align: left;
    float: left;
    font-size: 22px;
    letter-spacing: 2px;
    font-weight: 400;
    text-transform: uppercase;
    padding-left: 10px;
}

.legend a {
    width: 120px !important;
    height: 40px;
    background-color: rgba(255, 255, 255, .25) !important;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    opacity: 1 !important;
    transition: none !important;
    border: solid white 3px !important;
    color: white;
    line-height: 34px;
    float: right;
    margin: 10px 0 auto auto;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.legend a:hover {
    background-color: rgba(255, 255, 255, .5) !important;
}

.carousel .slide img {
    transform: translateY(calc((-1*(100vw - 500px) / 4)));

}



.carousel-status {
    display: none;
}

#SignupPopover div.MuiPopover-paper {
    border-radius: 20px !important;
}

.AddToListPopover {
    width: 50%;
    padding: 60px;
    background-color: white;
    text-align: center;
}

.PhoneScreen {
    box-shadow: 0 0 8px 8px rgba(0,0,0,.16);
}

.HomeSection1 {
    top: 100px;
    width: 100%;
    margin: 20px auto;
    padding-bottom: 20px;
    background-color: #fff;
}

.HomeSection2 {
    margin: 20px auto;
    padding-bottom: 20px;
}

.BannerHeading {
    padding-top: 20px;
    font-size: 40px;
}

.Black {
    color: black;
}

.White {
    color: white;
}

.HomeDivider {
    background-color: #212121;
    color: white;
    width: 100%;
    height: 49px;
    text-align: center;
    line-height: 49px;
    text-transform: uppercase;
    letter-spacing: 4px;
}

.DetailSection {
    width: 100%;
    height: 400px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    overflow: hidden;
}

.DetailOne {
    background-image: url('../images/steel.png');
    text-align: left;
    text-indent: 50px;
} 

.DetailTwo {
    background-image: url('../images/SolidWoodWhite.png');
} 

.DetailThree {
    background-image: url('../images/assembled.png');
} 

.DetailThree h2 {
    text-align: right;
    width: 90%;
}

.DetailHeadingContainer {
    width: 100%;
    background-color: white;
    padding: 2px 0;
}

.DetailHeading {
    font-weight: 700;
}

.ProductSection {
    width: 100%;
    height: 100vw;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    overflow: hidden;
    margin: 0 auto;
}

.CustomizeButton {
    width: 130px;
    height: 36px;
    background-color: #212121;
    line-height: 16px;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    margin: 360px auto auto auto;
    border-radius: 4px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.CustomizeButton:hover {
    background-color: #323232;
}

.CustomizeButton a {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    color: white;
    font-size: 16px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.LinkContainer {
    padding-top: 10px;
}

.ProductOne {
    background-image: url('../images/LaptopStand_Blue.png');
}

/*
.ProductOne .CustomizeButton {
    background-color: #FF4800;
}
*/

.ProductSection h3 {
    font-size: 22px;
    letter-spacing: 2px;
    font-weight: 400;
    text-transform: uppercase;
    background-color: rgba(0, 0, 0, .16);
    margin-top: 0px;
    height: 60px;
    line-height: 60px;
}

.ProductOne h3 {
    color: white;
}

.ProductTwo {
    background-image: url('../images/SideTable_Terracotta.png');
}

.ProductTwo h3 {
    color: white;
}

.ProductThree {
    background-image: url('../images/WritingDesk_Mint.png');
}

.ProductThree h3 {
    color: white;
}

.VerticalLogo {
    margin-top: 60px;
}

.MadeInUSA {
    margin: 40px auto;
}

#ListButton {
    margin-top: 360px;
}

.MuiPopover-paper-30 {
    transform: translate(calc(50vw - 160px), 30vh) !important;
}

.ProductSection {
    max-height: 500px;
}

@media only screen and (max-width: 500px) {
    .CustomizeButton {
        margin: 66vw auto auto auto;
    }

    #ListButton {
        margin-top: 66vw;
    }

    .carousel .slide img {
        transform: translateY(0);
    
    }

    .MadeInUSA {
        transform: translateY(-40px);
    }
    
}

@media only screen and (max-width: 800px) {
    .Build {
        width: 100%;
        display: grid;
        grid-template-rows: 1fr 1fr;
    }
    
    .Features {
        width: 100%;
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
    }

    .Products {
        width: 100%;
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
    }
    
}

@media only screen and (min-width: 800px) {
    .Build {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    
    .Features {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .Products {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .DetailSection {
        border: solid white 20px;
    }

    .DetailOne {
        text-align: center;
        text-indent: 0px;
    }

    .DetailThree h2 {
        text-align: center;
        width: 100%;
    }
}








.Footer {
    width: 100%;
    margin: 60px auto 0 auto;
}

.FooterBottom {
    border-top: solid #212121 10px;
    background-color: white;
    margin-bottom: 0px;
}

.FooterLinks {
    margin: 0 auto;
    padding-top: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

}

.FooterLinks a {
    text-decoration: none;
    text-transform: uppercase;
    color: #000000;
}

.Copyright {
    font-size: 12px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.SocialLinks {
    margin-top: 30px;
}

.SocialLinks a {
    margin: 0 20px;
}

@media only screen and (max-width: 800px) {
      
 
}

@media only screen and (max-width:500px) {
  

}